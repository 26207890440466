<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="联系我们" @moTitltFn="navPanel"></MoTitle>
    <div class="moSchoolContainer">
      <!-- 头部 -->
      <div class="moTitle center">
        <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">联系我们</h1>
        <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>

      <div class="contentBgColor center">
        <div style="box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);border-radius:10px;margin:20px 0;">
          <img src="@/assets/about/company.png" alt="" style="width:100%;border-radius: 10px 10px 0px 0px;">
          <div style="width:100%;padding: 20px;box-sizing: border-box;">
            <div style="font-size:18px;line-height: 28px;text-shadow: 0px 8px 24px rgba(0,0,0,0.15);font-weight: 600;color: #222222;">北京易普行科技有限公司</div>
            <div style="width: 30px;height: 4px;background: #D70C0C;box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);margin:14px 0;"></div>
            <div style="color:#444">
              <span>总部地址：</span>
              <span>北京市海淀区上地三街9号D座3(2)层D311</span>
            </div>
            <div style="padding:10px 0;color:#444">
              <span>电&emsp;&emsp;话：</span>
              <span>010-60780718</span>
            </div>
            <div style="color:#444">
              <span>邮&emsp;&emsp;箱：</span>
              <span>market@epxing.com</span>
            </div>
          </div>
        </div>

        <h2 class="h2Desc">分支机构</h2>
        <span class="spanDesc"></span>
        <div style="font-size: 15px;font-weight: 400;color: #222222;line-height: 24px;margin:20px 0;">
          7家分公司，15个办事处，第一时间为您提供专业服务
        </div>
        <div>
          <div v-for="(item,index) in jgData" :key="index" class="companyItem">
            <div style="font-size: 18px;font-weight: 600;color: #222222;line-height: 28px;">{{item.title}}</div>
            <div class="companyLine"></div>
            <div class="compantText">
              <span>地址：</span>
              <span>{{item.address}}</span>
            </div>
            <div class="compantText">
              <span>电话：</span>
              <span>{{item.phone}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <MoBottom></MoBottom>
      <MoFoot></MoFoot>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
export default {
  name: 'moRelationUs',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      jgData: [
        { title: '武汉分公司', address: '武汉市武昌区中南路2-6号中建广场B座22层A室', phone: '13986139776' },
        { title: '广州分公司', address: '广东省广州市海珠区江南大道中180号富力天域中心3203', phone: '13510559311' },
        { title: '南京分公司', address: '南京市玄武区珠江路657号锦创数字产业园C座517', phone: '18516551136' },
        { title: '上海分公司', address: '上海市普陀区中山北路2000号中期大厦925室', phone: '18516551136' },
        { title: '西安分公司', address: '陕西省西安市碑林区中贸广场12号楼2803', phone: '15001241965' },
        { title: '成都分公司', address: '成都市金牛区花照壁西顺街388号龙湖紫宸香颂四期3栋1单元804', phone: '18680763410' },
        { title: '长沙办事处', address: '湖南省长沙市岳麓区潇湘中路269号', phone: '13986139776' },
        { title: '重庆办事处', address: '重庆市沙坪坝区永年路101号', phone: '18680763410' },
        { title: '深圳办事处', address: '广东省深圳市罗湖区东湖路6号', phone: '13510559311' },
        { title: '长春办事处', address: '吉林省长春市前进大街2699号', phone: '13331150113' },
        { title: '沈阳办事处', address: '辽宁省沈阳市皇姑区郡原悦城', phone: '13331150113' },
        { title: '哈尔滨办事处', address: '黑龙江省哈尔滨市松北区松浦街道观江国际A区', phone: '13331150113' },
        { title: '杭州分公司', address: '浙江省杭州市北软路万家名城1号', phone: '17316088617' },
        { title: '济南办事处', address: '山东省济南市历下区工业南路55号', phone: '17600895431' },
        { title: '青岛办事处', address: '青岛市城阳区夏庄街道银河路666号', phone: '17600895431' },
        { title: '郑州办事处', address: '河南省郑州市金水区农业路与天明路交叉口索克时代大厦A803', phone: '13986139776' },
        { title: '昆明办事处', address: '云南省昆明市官渡区紫金中心', phone: '17600856333' },
        { title: '合肥办事处', address: '安徽省合肥市包河区大摩广场4号楼', phone: '18516551136' }
      ]

    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/relationUs')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/RelationUs/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moSchoolContainer{
  padding-top: 2.75rem;
}
.moCase{
  width: 100%;
  margin-top: 1.875rem;
}

.knowMoreBtn{
  width: 120px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 30px;
  border: 1px solid rgba(211,218,228,0.5);
  color: #444444;
  font-size: 14px;
}
.companyItem{
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid rgba(220, 223, 230, 0.5);
  margin-bottom: 20px;
}
.companyLine{
  width: 30px;
  height: 4px;
  background: #D70C0C;
  margin: 14px 0;
}
.compantText{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 26px;
}
</style>
